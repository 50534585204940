import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import CFS from '../components/molecules/CFS';

import imgSecSection from '../assets/images/pages/que-significa-ele/secondImg.png';
import imgMaria from '../assets/images/pages/conoce-al-equipo/maria.png';
import imgRichard from '../assets/images/pages/conoce-al-equipo/richard.png';

import '../assets/styles/pages/conoce-al-equipo.scss';

const ConocoeAlEquipo = () => (
  <Layout
    title="Conoce al Equipo de ELE Barcelona"
    description="ELE Barcelona está formada por Maria Blanco, Directora de estudios. Richard Davie, Director de la escuela. ¡Conócenos!"
  >
    <div className="conoce-al-equipo">
      <CFS title="Conoce al equipo" />
      <section className="second-section c-section-py-80">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="cta">
                <div className="left">
                  <h2 className="cta__title">
                    ELE Barcelona está formado por un equipo único e internacional que comparte
                    puntos en común:{' '}
                  </h2>
                  <p className="cta__description">
                    nos encantan los idiomas, nos encanta aprender y nos encanta trabajar en un
                    ambiente positivo donde enseñar un idiomas es posible. Conoce al equipo que hace
                    posible la enseñanza del español en Barcelona.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-7">
              <div className="left">
                <h3 className="left__title c-title-34">María Blanco</h3>
                <p className="left__description c-text-16">
                  La curiosidad de María por aprender y enseñar la ha convertido en una profesora
                  apasionada y versátil y una motivada formadora de profesores. María cofundó ELE
                  Barcelona y, desde entonces, se ha dedicado a formar a profesores y profesoras y
                  ser parte del equipo directivo. Le encanta bailar, tocar instrumentos raros,
                  coleccionar vinilos y dar paseos por la montaña. Su rincón favorito de Barcelona
                  es el el parque del Collserola para recargar energías en plena naturaleza.
                  <br />
                  <br /> María es responsable de la dirección académica, la creación y planificación
                  de los contenidos de los cursos, así como de la formación de futuros profesores de
                  español.
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="right">
                <img className="cta-img" src={imgMaria} alt="imgCTA" />
                <div className="overlap">
                  <p>Cofundadora y directora académica</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-9">
              <p className="c-title-20">Titulación y logro</p>
              <ul className="left__list">
                <li>Licenciada en Ciencias de la Comunicación, Universidad de Sevilla, 2002.</li>
                <li>
                  Formadora de español como lengua extranjera, International House Clic Sevilla,
                  2007.
                </li>
                <li>
                  Formación de profesora de ENE: español de los negocios. Instituto Superior de
                  Estudios Lingüísticos y traducción - ISTRAD, 2013
                </li>
                <li>
                  Formación avanzada para profesores de español: gramática, léxico y evaluación,
                  International House e Instituto Cervantes, 2011
                </li>
                <li>
                  {' '}
                  Formación de formadores. Diseñar e impartir un taller de formación, Instituto
                  Cervantes, 2015.
                </li>
                <li>
                  {' '}
                  Acreditación de examinadores orales DELE A1-A2, B1- B2, C1, Instituto Cervantes de
                  Budapest, de 2009 a 2018.
                </li>
                <li>
                  {' '}
                  Gramática cognitiva en la clase de español, Formación ELE / International House,
                  2014
                </li>
                <li> Gamificación el aula de segundas lenguas, Instituto Cervantes, 2017</li>
                <li>
                  {' '}
                  La música en el aula de ELE, cursos internacionales Universidad de Salamanca, 2018
                </li>
                <li>
                  {' '}
                  Recursos audiovisuales en el aula de ELE: radio, publicidad, cine y televisión,
                  Universidad Menéndez y Pelayo - UIMP, 2016
                </li>
                <li>
                  {' '}
                  La enseñanza de modismos en los niveles superiores ELE, Universidad Menéndez y
                  Pelayo - UIMP, 2018
                </li>
                <li>
                  {' '}
                  Curso de iniciación al chino-mandarín: acercamiento intuitivo y progresivo en la
                  enseñanza ELE, ISTRAD, 2014
                </li>
                <li> Formación de profesores de ELE International House Clic Sevilla, 2008.</li>
                <li>
                  {' '}
                  “Encuentros Prácticos de Profesores de Español como Lengua Extranjera”
                  International House, Barcelona, 2014, 2015, 2016, 2017
                </li>
                <li> ELE Barcelona. Fundación y dirección. </li>
                <li> Languages4Life. Dirección académica.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="third-section c-section-py-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="right">
                <h3 className="right__title c-title-34">Richard Davie</h3>
                <p className="right__description c-text-16">
                  Richard es de Escocia y estudió ciencias químicas en la Universidad de Edimburgo.
                  Después de finalizar y ganar muchos años de experiencia como profesor de inglés,
                  montó TEFL Iberia para crear un curso moderno de formación de profesores de inglés
                  en Barcelona. <br />
                  <br /> Hoy en día TEFL Iberia es una de las escuelas más importantes de formación
                  de profesores de inglés en Europa. Tras el gran éxito de TEFL Iberia, ayudó a
                  crear ELE Barcelona con la misma filosofía – una escuela moderna con cursos
                  interesantes, amenas y divertidos con profesores profesionales y dedicados.
                  <br />
                  <br /> Es responsable de la gestión y funcionamiento diario de la escuela, así
                  como la organización y promoción de los cursos.
                </p>
                <p className="c-title-20">Titulación y logro</p>
                <ul className="right__list">
                  <li> MChem (Ciencias Químicas), Universidad de Edimburgo.</li>
                  <li>
                    {' '}
                    CELTA (Cambridge English Language Teacher Award), International House,
                    Barcelona.
                  </li>
                  <li> DELE C1, Instituto Cervantes.</li>
                  <li> TEFL Iberia: centro validado por Trinity College London, Reino Unido</li>
                  <li>
                    {' '}
                    TEFL Iberia: la escuela se encuentra entre las 3 primeras a nivel internacional.
                  </li>
                </ul>
                <p className="c-title-20">Algunas de sus publicaciones:</p>
                <ul className="right__list">
                  <li>
                    {' '}
                    Teaching English as a Foreign Language, Blog profesional de la Universidad de
                    York.
                  </li>
                  <li>
                    {' '}
                    Teaching English after University, Blog profesional de la Universidad de
                    Edimburgo.
                  </li>
                  <li> 5 questions answered about using your TEFL qualification, LSMedia.</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="left">
                <img src={imgRichard} alt="Happy person" />
                <div className="overlap">
                  <p>Cofundador y director de comunicación</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  </Layout>
);

export default ConocoeAlEquipo;
